import React from 'react';
import "./navBar.css";
import Logo from "../../icons/Full_Logo.png";

export const NavBar = ({route}) => {
  const url = "https://app.lifemngr.com/";

  const ChangeRoute = (route, title) => {
    //window.history.pushState(null, title, route);
    window.open(route, "_self")
  }

  return (
    <div className='navBar'>
      <img src={Logo} className="logo" alt="life-manager"/>
      <div className='links'>
        <h3 
          className='link'
          onClick={()=>ChangeRoute("/", "Life Manager")}
          style={{fontWeight: route === "/" ? "bold" : "lighter"}}
        >Home</h3>
        <h3 
          className='link'
          onClick={()=>ChangeRoute("/support", "Life Manager")}
          style={{fontWeight: route === "/support" ? "bold" : "lighter"}}
        >Support</h3>
        <h3 
          className='link'
          onClick={()=>ChangeRoute("/about", "Life Manager")}
          style={{fontWeight: route === "/about" ? "bold" : "lighter"}}
        >About</h3>
        <h3 
          className='link'
          onClick={()=>ChangeRoute("/privacy", "Life Manager")}
          style={{fontWeight: route === "/privacy" ? "bold" : "lighter"}}
        >Privacy Policy</h3>
        <h3 
          className='link'
          onClick={()=>window.open(url)}
          style={{fontWeight: "bold"}}
        >Log In</h3>
      </div>
    </div>
  )
}
