import React, { useRef } from 'react';
import "./home.css";
import IphoneX from "../../icons/iphonex.png";
import Laptop from "../../icons/laptop2.png";
import Circle from "../../icons/circle.png"

export default function Home() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const url = "https://app.lifemngr.com/";

  var phone = windowSize.current[0] <= 500;

  return (
    <div className='home' style={phone ? {flexDirection: "column"} : {overflowY: "hidden"}}>
      <div className='textCon' style={{marginTop: "70px"}}>
        <h1 className='homeTitle1' style={phone ? {textAlign: "center", fontSize: "3vh"} : {}}>ORGANIZE WHAT </h1>
        <h1 className='homeTitle2'style={phone ? {textAlign: "center",  lineHeight: "7vh", height: "8vh", fontSize: "7vh", width: "70%", alignSelf: "center"} : {}}>MATTERS</h1>
        <h1 className='homeTitle1' style={phone ? {textAlign: "center", fontSize: "3vh", lineHeight: "10px"} : {}}>to you in one place.</h1>
        <h1 className='homeTitle3' style={phone ? {textAlign: "center",  fontSize: "3vh"} : {}}>Organize your life.</h1>
        <button className='appButton' style={phone ? {marginTop: "0px", width: "250px"} : {}} onClick={()=>{
          setTimeout(() => {
            window.open(url)
          }, 300);
        }}>Go to the App!</button>
      </div>
      <div style={phone ? {top: "10px", position: "relative"} : {}}>
        {phone
          ? null
          : <img src={Circle} className='circle-web' alt=''/>
        }
        <div className={phone ? 'phoneCon' : ""} style={phone ? {} : {position: "absolute", left: "-5vh", top: "-0vh"}}>
          <img src={Laptop}  className='laptop' style={phone ? {} : {height: "40vh"}} alt=''/>
        </div>
        <div className={phone ? 'phoneCon' : ""} style={phone ? {right: "-220px", top: "-70px"} : {position: "absolute", left: "20vh", top: "45vh"}}>
          <img src={IphoneX} className='iphoneX' style={phone ? {} : {height: "45vh"}} alt=''/>
        </div>
      </div>
    </div>
  )
}
