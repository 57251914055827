import React from 'react';
import "./thankYou.css";
import Logo from "../../icons/Full_Logo.png"

export default function ThankYou() {
  return (
    <div className='support'>
      <div className='supportTextCon'>
        <h1 className='supportTitle'>
          Thank You!
        </h1>
        <h1 className='supportText'>
          Thank you for registering!<br/>
          You can now return to the application and sign in<br/>
          Enjoy!😊
        </h1><br/>
        <img src={Logo} className="logo" alt="life-manager"/>
      </div>
    </div>
  )
}
