import React from 'react';
import "./thankYou.css";
import Logo from "../../icons/Full_Logo.png";
import Error400 from "../../icons/400.png";

export default function Invalid() {
  return (
    <div className='support'>
      <div className='supportTextCon'>
        <h1 className='supportTitle'>
          Error!
        </h1>
        <h1 className='supportText'>
          Invalid Request!☹️
        </h1><br/>
        <img src={Error400} className="logo" alt="life-manager" style={{width: 150, margin: 0}}/><br/>
        <img src={Logo} className="logo" alt="life-manager"/>
      </div>
    </div>
  )
}
