import React, { useEffect } from 'react';
import "./appStoreLink.css";

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) return "Windows Phone";
  if (/android/i.test(userAgent)) return "Android";
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
  return "unknown";
}

export default function AppStoreLink() {

  useEffect(() => {
    const system = getMobileOperatingSystem();
    switch(system) {
      case "iOS":
        window.open("https://apps.apple.com/us/app/id1602528403", "_self");
        break;
      case "Android":
        window.open("https://play.google.com/store/apps/details?id=com.lifemngr.com.lifemanager&hl=en&gl=US", "_self");
        break;
      default:
        window.open("https://app.lifemngr.com", "_self");
    }
  }, [window.location])
  

  return (
    <div className='appStoreLink'>
      <h1 className='homeTitle' style={{fontSize: "5vh", textAlign: "center"}}>DOWNLOAD NOW!!</h1>
    </div>
  )
}