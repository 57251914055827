import React from 'react';
import "./support.css";

export default function Support() {
  return (
    <div className='support'>
      <div className='supportTextCon'>
        <h1 className='supportTitle'>Support</h1>
        <p className='supportText'>
          You can contact us for any question or suggestions at: 
          <a href={`mailto:contact.lifemngr@gmail.com`} className='highlight'> contact.lifemngr@gmail.com </a>
        </p>
      </div>
    </div>
  )
}
