import React from 'react';
import "./about.css";

export default function About() {
  return (
    <div className='support'>
      <div className='supportTextCon' style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
        <div style={{height: "150px"}}/>
        <h1 className='aboutTitle' style={{textDecoration: "none"}}>
          We are here to change the world and to
          make <span className='highlight'>YOUR</span> life easier. We provide you with
          the power to organize your life <span className='highlight'> the way you
          want it to be. </span>
        </h1>
        <div style={{height: "300px", width: "30px"}}/>
        <p className='supportText'>
          Our application comes to provide better comfort in adding notes under specific<br/>
          categories with pre-determined fields. You can use the App’s existing categories with the<br/>
          provided fields or create your own categories with your own fields.
        </p>
        <div style={{height: "150px", width: "30px"}}/>
        <h1 className='' style={{textDecoration: "underline"}}>The Team</h1>
        <div className='teamCon'>
          <div className='teamMember'>
            <h1>Eli Eyal</h1><br/>
            <h1 className='teamJob'>Founder & Chief<br/> Architect</h1>
          </div>
          <div className='teamMember'>
            <h1>Shlomit Eyal</h1><br/>
            <h1 className='teamJob'>Co-Founder</h1>
          </div>
          <div className='teamMember'>
            <h1>Alon Eyal</h1><br/>
            <h1 className='teamJob'>Chief Developer</h1>
          </div>
          <div className='teamMember'>
            <h1>Orit Eyal</h1><br/>
            <h1 className='teamJob'>Chief Designer</h1>
          </div>
        </div>
        <div style={{height: "150px", width: "30px"}}/>
      </div>
    </div>
  )
}
