import './App.css';
import { Routes , Route, useLocation  } from 'react-router-dom';
import Home from './pages/home/home';
import {NavBar} from './components/navBar/navBar';
import Support from './pages/support/support';
import About from './pages/about/about';
import Privacy from './pages/privacy/privacy';
import ThankYou from './pages/signUpMessage/thankYou';
import Invalid from './pages/signUpMessage/invalid';
import AppStoreLink from './pages/appStoreLink/appStoreLink';
import { DownloadApk } from './pages/downloadApk/downloadApk';

function App() {
  return (
    <div className="App">
      {useLocation().pathname !== "/privacy" ? <div className='Background'/> : null}
      <NavBar route={useLocation().pathname}/>
      <Routes>
        <Route exact path="/support" element={<Support />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/downloadApk" element={<DownloadApk />} />
        <Route exact path="/download" element={<AppStoreLink />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/thankYou" element={<ThankYou />} />
        <Route exact path="/invalid" element={<Invalid />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
