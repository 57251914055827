import React from 'react';
import "./downloadApk.css";
import { useSearchParams } from 'react-router-dom';

export const DownloadApk = () => {
  const [searchParams,] = useSearchParams();
  
  var code = searchParams.get("code");
  if(code!=="IReadThePassword111")
    return null;
  return <div className='download'>
    <h1 className='apkTitle'>Download Apk - 0.6.11</h1>
    <button className="btn" onClick={()=>window.open("/life_manager.apk")}><i className="fa fa-download"></i> Download</button>
  </div>
}